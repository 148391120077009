import { client_ } from ".";
import { ActorDefaultLocal } from "./config/actor";
import { ActorButtonCalibrate, ActorButtonCalibrateWrapper, ActorButtonClearError, ActorButtonClearTransport, ActorButtonHeadClean, ActorButtonMainMode, ActorButtonPassthroughMode, ActorButtonStatusWrapper, ActorModeButtons } from "./core/ActorComponentButton";
import { SlidersCMYK } from "./core/ActorComponentSliders";
import { ActorLogField, ActorTextFieldServiceTray, ActorTextFieldSpeed, ActorTextfieldGeneric } from "./core/ActorComponentTextbox";
import { ComponentButtonTitle } from "./core/ComponentButtonTitle";
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import AllInboxOutlinedIcon from '@mui/icons-material/AllInboxOutlined';
import CallToActionOutlinedIcon from '@mui/icons-material/CallToActionOutlined';

import {withTranslation} from "react-i18next";
import {t} from 'i18next';

let propsTestCMYKSlider = {
    c: 10,
    m: 20,
    y: 30,
    k: 40,
    c_warning: true,
    m_warning: true,
    y_warning: false,
    k_warning: false,
    c_out: false,
    m_out: true,
    y_out: false,
    k_out: false
}
const buttonSX = {
    border: "1px solid grey",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 2,
    marginRight: 1,
    marginTop: 1,
    color: "white"
}

export function PanelRight(props: any) {
    const style = { height: 60, fontWeight: "bold", fontSize: 18 }
    return (<div style={{ height: "100%", display: "flex", flexDirection: "column", margin: 10, justifyContent: "space-evenly" }}>
        <ActorButtonStatusWrapper style={{ fontWeight: "bold", fontSize: 18 }} text={t("no_connection")} settings={{
            // onDoubleClick: () => { client_.sendRestartRequest() },
            variant: "outlined", sx: {
                margin: 2, backgroundColor: "inherit", ...buttonSX,
                ":hover": { backgroundColor: "inherit" }
            }
        }}></ActorButtonStatusWrapper>

        <ActorLogField settings={{ sx: { marginLeft: 1, marginRight: 1 }, InputProps: { multiline: true, maxRows: 4, readOnly: true, style: { height: 70, fontSize: 12 } } }}>

        </ActorLogField>
        <div style={{ marginLeft: 10, marginRight: 10 }}>

            {<SlidersCMYK sliderProps={propsTestCMYKSlider} />}
        </div>

        {/* <div style={{ marginLeft: 7, marginRight: 10 }}>

            <ActorSliderGenericSpray max={100} defaultValue={10}></ActorSliderGenericSpray>
            <ActorSliderGenericPrinter max={100} defaultValue={35}></ActorSliderGenericPrinter>
        </div> */}


        <div style={{ marginLeft: 7, marginRight: 10 }}>
        <ActorTextFieldServiceTray icon={
                <CallToActionOutlinedIcon style={{ marginRight: 5, width: 20, fontWeight: "bold", transform: "scale(1.5)" }}></CallToActionOutlinedIcon>} actor_name="ActorServiceTray" style={{ marginLeft: 10 }}></ActorTextFieldServiceTray>
            <ActorTextfieldGeneric icon={
                <SavingsOutlinedIcon style={{ marginRight: 5, width: 20, fontWeight: "bold", transform: "scaleX(-1) scale(1.5)" }}></SavingsOutlinedIcon>} actor_name="ActorLastJobCost" style={{ marginLeft: 10 }}></ActorTextfieldGeneric>
            <ActorTextFieldSpeed icon={
                <SpeedOutlinedIcon style={{ marginRight: 5, width: 20, fontWeight: "bold", transform: "scale(1.5)" }}></SpeedOutlinedIcon>} actor_name="ActorSpeed" style={{ marginLeft: 10 }}></ActorTextFieldSpeed>
        </div>

        <ActorButtonCalibrateWrapper style={{...style}} settings={{sx:{...buttonSX}}}></ActorButtonCalibrateWrapper>


        <ActorButtonHeadClean style={{ ...style }} settings={{
            variant: "outlined", sx: {
                ":hover": {
                    backgroundColor: "rgba(165, 145, 0, 1)", color: "white", border: "1px solid black"
                }, backgroundColor: "#c3b900", ...buttonSX
            }
        }}>{ComponentButtonTitle({ icon: <img alt='' src="images/headclean.png" style={{ width: 30, height: 30 }} />, title: t("head_clean") })}</ActorButtonHeadClean>
        <ActorButtonClearTransport style={{ ...style }} settings={{
            variant: "outlined", sx: {
                ":hover": {
                    backgroundColor: "rgba(200, 110, 3, 1)", color: "white", border: "1px solid black"
                }, backgroundColor: "#F29F03", ...buttonSX
            }
        }}>
            {ComponentButtonTitle({ icon: <img alt='' src="images/cleartransport.png" style={{ width: 35, height: 35, transform: "scale(1.5)" }} />, title: t("clear_transport") })}
            {/* <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                <img src="cleartransport.png" style={{width:35, height:35, transform:"scale(1.5)"}}/>
                <p style={{ flex: 5 }}>CLEAR TRANSPORT</p>
            </div> */}</ActorButtonClearTransport>
        <ActorButtonClearError style={{ ...style }} settings={{
            variant: "outlined", sx: {
                ":hover": {
                    backgroundColor: "rgba(210, 0, 0, 1)", color: "white", border: "1px solid black"
                }, backgroundColor: "#FE0000", ...buttonSX
            }
        }}><div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                <img alt='' src="images/clear_error.svg" style={{ flex: 1 }} />
                <p style={{ flex: 5 }}>{t("clear_error")}</p>
            </div></ActorButtonClearError>
        {/* <ActorPassthroughSwitchWrapper style={{ ...style }} settings={{
            variant: "outlined", sx: {
                ":hover": {
                    backgroundColor: "rgba(100, 150, 69, 1)", color: "white", border: "1px solid black"
                }, backgroundColor: "#73BF45", ...buttonSX
            }
        }}></ActorPassthroughSwitchWrapper> */}
        <ActorModeButtons main_button={
            { style: { ...style }, buttonSX: buttonSX }
        }
            sub_button={
                { style: { ...style }, buttonSX: buttonSX }}></ActorModeButtons>
    </div>)
} 