import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material';
import { ActorSocketIOViewMain } from './ActorSocketIOView';
import { v4 as uuidv4 } from 'uuid';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import translation_hu from "./translations/hu.json";
import translation_en from "./translations/en.json";

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: localStorage.getItem("translation") || "en",                              // language to use
  resources: {
    hu: {
      translation: translation_hu
    },
    en: {
      translation: translation_en
    }
  },
});



declare module '@mui/material/styles' {
  interface TypeBackground {
    hovered: string;
  }
}
const theme = createTheme({
  palette: {
    background: {
      default: '#f5f5f5',
      hovered: 'rgba(0, 0, 0, 0.09)',
    },
  },
});

export let client_ = new ActorSocketIOViewMain("main_view_" + uuidv4(),
  localStorage.getItem("backend_url") || window.config_quadient?.actor_socketio_registry_url || "http://localhost:9091");
ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>

    </I18nextProvider>
  </React.StrictMode>, document.getElementById('root')
);
