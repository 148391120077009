
export class MsgCounterSensorEntry {
    public counter_sensor_entry: number = 0;
    public constructor(init?: Partial<MsgCounterSensorEntry>) {
        Object.assign(this, init);
    }
}

export class MsgCounterSensorExit {
    public counter_sensor_exit: number = 0;
    public constructor(init?: Partial<MsgCounterSensorExit>) {
        Object.assign(this, init);
    }
}

export class MsgCounterPagesPrinted {
    public counter_pages_printed: number = 0;
    public constructor(init?: Partial<MsgCounterPagesPrinted>) {
        Object.assign(this, init);
    }
}

export class MsgCounterPagesSkipped {
    public counter_pages_skipped: number = 0;
    public constructor(init?: Partial<MsgCounterPagesSkipped>) {
        Object.assign(this, init);
    }
}

export class MsgCounterPagesBuffered {
    public counter_pages_buffered: number = 0;
    public constructor(init?: Partial<MsgCounterPagesBuffered>) {
        Object.assign(this, init);
    }
}

export class MsgCounterPagesParsed {
    public counter_pages_parsed: number = 0;
    public constructor(init?: Partial<MsgCounterPagesParsed>) {
        Object.assign(this, init);
    }
}

export class MsgCounterJobsReceiving {
    public counter_jobs_receiving: number = 0;
    public constructor(init?: Partial<MsgCounterJobsReceiving>) {
        Object.assign(this, init);
    }
}

export class MsgCounter {
    public counter_sensor_entry: number | undefined = undefined;
    public counter_sensor_exit: number | undefined = undefined;
    public counter_pages_printed: number | undefined = undefined;
    public counter_pages_skipped: number | undefined = undefined;
    public counter_pages_buffered: number | undefined = undefined;
    public counter_pages_parsed: number | undefined = undefined;
    public counter_jobs_receiving: number | undefined = undefined;
    public constructor(init?: Partial<MsgCounter>) {
        Object.assign(this, init);
    }
}