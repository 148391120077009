export class MsgRequestProxy {
    vmid?: string;
    type?: string;
    constructor(init?: Partial<MsgRequestProxy>) {
        Object.assign(this, init);
    }
}

export class MsgResponseProxy {
    pveCookie?: string;
    csrf?: string;
    port?: string;
    vnc_ticket?: { ticket: string, port: string };
    constructor(init: Partial<MsgResponseProxy>) {
        Object.assign(this, init);
    }
}