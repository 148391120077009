
export function ComponentButtonTitle(props: { icon: JSX.Element, title: string, subtitle?: string }) {

    function basic() {
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                {props.icon}
                <p style={{ flex: 5, lineHeight: 1.1 }}>{props.title}</p>
             </div>
        )
    }
    function subtitled() {
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                {props.icon}
                <div style={{display:"flex", flexDirection:"column"}}>
                <p style={{ flex: 5, lineHeight: 1.1 }}>{props.title}</p>
                {props.subtitle && <p style={{ flex: 5, lineHeight: 1.1, fontSize: 12 }}>{props.subtitle}</p>}
           
                </div>
             </div>
        )
    }
    return (
        <>
            {props.subtitle ? subtitled() : basic()}
        </>
    )

}