import { TIdActor } from "@ginf/appcore-actor";

export class MsgRegisterView
{
    public constructor(init?: Partial<MsgRegisterView>)
    {
        Object.assign(this, init);
    }

    public actor_id: TIdActor | undefined;
};