export enum ModePrintSub {
    PASS_THROUGH,
    DYNAMIC,
    STATIC_FM,
    STATIC_RIP
}

// this message is used to send information about the current sub print mode to the UI,
// since the sub print mode is set by the Inserter and not by the UI, the UI only needs to show it.

export class MsgPrintModeSub {
    mode_print_sub: ModePrintSub | undefined;

    constructor(init: Partial<MsgPrintModeSub>) {
        Object.assign(this, init);
    }
}