import { ActorSocketIO as LegacyActorSocketIO } from "appcore-ts/actor/actor/actor_socketio"
import {MsgPrintModeSub} from "./msg/MsgPrintModeSub"
import { MsgRequestCurrentPrintModeMain } from "./msg/MsgRequestCurrentPrintModeMain"
import { MsgSetPrintModeMain, MsgSetPrintModeMainResult } from "./msg/MsgSetPrintModeMain"
import { MsgSetInstantPassThrough,MsgSetInstantPassThroughResult } from "./msg/MsgSetInstantPassThrough"
import { actor_message_broker } from "./config/actor"

export class ActorSIOBridge extends LegacyActorSocketIO{
    protected store_: any = {
        MsgPrintModeSub, MsgRequestCurrentPrintModeMain, MsgSetPrintModeMain, MsgSetInstantPassThrough,
        MsgSetInstantPassThroughResult,MsgSetPrintModeMainResult
    }
    process<T>(message: T, id: string): void {
        
        //actor_message_broker.emit(message, id);
    }
}