export class MsgActionPassthrough {
    public is_enabled: boolean = false;
    public constructor(init?: Partial<MsgActionPassthrough>) {
        Object.assign(this, init);
    }
}
export class MsgActionClearTable {
    public constructor(init?: Partial<MsgActionClearTable>) {
        Object.assign(this, init);
    }
}

export class MsgActionClearError {
    public constructor(init?: Partial<MsgActionClearError>) {
        Object.assign(this, init);
    }
}

export class MsgActionCancelJob {
    public constructor(init?: Partial<MsgActionCancelJob>) {
        Object.assign(this, init);
    }
}
export class MsgActionCalibrateMediaPath {
    public constructor(init?: Partial<MsgActionCalibrateMediaPath>) {
        Object.assign(this, init);
    }
}

export enum EnumMaintenance {
    LIGHT = 0,
    MEDIUM = 1,
    MIDJOB = 2,
}

export class MsgActionMaintenance {
    public enum_maintenance: EnumMaintenance = EnumMaintenance.LIGHT;
    public constructor(init?: Partial<MsgActionMaintenance>) {
        Object.assign(this, init);
    }
}

export class MsgAction {
    public action_passthrough: MsgActionPassthrough | undefined;
    public action_clear_table: MsgActionClearTable | undefined;
    public action_clear_error: MsgActionClearError | undefined;
    public action_maintenance: MsgActionMaintenance | undefined;
    public action_cancel_job: MsgActionCancelJob | undefined;
    public action_calibrate: MsgActionCalibrateMediaPath | undefined;
    public constructor(init?: Partial<MsgAction>) {
        Object.assign(this, init);
    }
}

export class MsgResultAction {
    public is_successful: boolean = false;
    public info_status: string = "";
    public constructor(init?: Partial<MsgResultAction>) {
        Object.assign(this, init);
    }
}