import { ToggleButtonGroup, ToggleButton, styled, SvgIcon, Typography, Modal, Button, TextField, Box, Card } from "@mui/material"
import React from "react"
import { ActorVNC_Screen } from "./VNCScreen"
import { v4 as uuidv4 } from 'uuid';
import { ActorDefaultLocal } from "../config/actor";
import { stage_quadient } from "../config/stage";
import { MsgSetStateLock, StateLock } from "../msg/MsgLockControl";
import { client_ } from "..";
import { Done } from "@mui/icons-material";
import { TextFieldWithKeyboard } from "./TextFieldWithKeyboard";
// import bcrypt from "bcryptjs-react"

const StyledSideMenu = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 93,
    backgroundColor: theme.palette.background.default
}))

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#f50057",
    ":hover": {
        backgroundColor: "#c51162",
    },
    ":disabled": {
        backgroundColor: "rgba(0, 0, 0, 0.12)",
    }
}))


const ID_DEFAULT_PAGE = 2

interface ISettingsPageState {
    tabIndex: number;
    vnc_password: Array<string>;
    lock_active: boolean;
    lock_modal_open: boolean;
    password: string;
    lock_btn_disabled: boolean;
    wrong_password?: boolean;
}

export class SettingsPage extends React.Component<{}, ISettingsPageState> {

    actor = new ActorDefaultLocal("actor_settings_page", stage_quadient)

    iframes: Array<JSX.Element> = []
    iframe_map_settings = new Map()
    constructor(props: any) {
        super(props)
        this.state = {
            tabIndex: 0,
            vnc_password: [],
            lock_active: true,
            lock_modal_open: false,
            password: "",
            lock_btn_disabled: false,
        }

        this.actor.process = this.process.bind(this);
    }
    process<T>(message: T, id: string): void {
        if (message instanceof MsgSetStateLock) {

            this.setState({ lock_active: message.state_lock === 0 ? true : false })

            if (message.state_lock === StateLock.UNLOCKED) {
                this.setState({ lock_modal_open: false })
            }
            if (message.state_lock === 0 && message.wrong_password === true) {
                this.setState({ wrong_password: true })
                setTimeout(() => {
                    this.setState({ wrong_password: false, lock_btn_disabled: false })
                }, 5000);
            }
            else {
                if (message.state_lock === StateLock.LOCKED) {
                    this.setState({ tabIndex: ID_DEFAULT_PAGE })
                }
            }

            this.setState({ lock_btn_disabled: false })
        }
        else if (message as unknown as String === "reset") {
            if (this.state.lock_active === true) {

                this.setState({ tabIndex: ID_DEFAULT_PAGE, password: "" })
            }
        }


    }
    componentDidMount() {
        this.setState({ tabIndex: ID_DEFAULT_PAGE, password: "" })
    }
    createSideTabs() {
        // console.log(window.config_quadient?.settings_pages)
        return window.config_quadient?.settings_pages?.map((page, index) => {
            let style = {}
            if (page.id === this.state.tabIndex) {
                style = { boxShadow: "inset 0px 0px 5px 1px #ababab", backgroundColor: "rgba(0, 0, 0, 0.08)", fontWeight: "bold" }

            }
            if (page.id !== ID_DEFAULT_PAGE && this.state.lock_active === true) {
                return <></>
            }
            return (

                <ToggleButton sx={{ ...style }} onDoubleClick={() => { this.forceUpdate() }} key={index} onClick={
                    () => {
                        this.setState({ tabIndex: page.id })
                    }} style={{ padding: 0, borderRadius: 0, height: 93, width: 93, display: "flex", flexDirection: "column" }} value={page.id}>
                    <SvgIcon>
                        <path fill="currentColor" d={page.page_icon} />
                    </SvgIcon>
                    <Typography fontWeight={page.id === this.state.tabIndex ? "bold" : ""} paddingTop={1} variant="subtitle2" fontSize={12}>{page.title}</Typography>
                </ToggleButton>
            )
        })
    }
    showWrongPassword() {

        setTimeout(() => { this.setState({ wrong_password: false }) }, 5000)
        return
    }
    createModal() {
        return <Modal
            open={this.state.lock_modal_open}
            onClose={() => { this.setState({ lock_modal_open: false, password: "" }) }}
        >
            <div style={{ position: 'absolute', top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: 400, height: 250, backgroundColor: "white", borderRadius: 10 }}>
                {this.createLockScreen()}
            </div>
        </Modal>
    }
    createLockScreen() {
        if (this.state.lock_active === true) {
            return (
                <div style={{ margin: "auto", height: "100%" }}>
                    <div style={{ padding: 10, display: "flex", flexDirection: "column", justifyContent: "center", height: "100%", marginLeft: "auto", marginRight: "auto" }}>
                        <Card elevation={1} style={{ width: "400px", margin: "auto" }}>
                            <div>
                                <Typography variant="h6" style={{ textAlign: "center", paddingTop: 20 }}>Enter password for advanced settings</Typography>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", padding: 20, paddingBottom: 0 }}>
                                <Typography variant="h6">Password</Typography>
                                <TextFieldWithKeyboard
                                    keyboard_type="normal"
                                    type="password"
                                    size="small"
                                    value={this.state.password}
                                    onChange={(e) => { this.setState({ password: e.target.value }) }}
                                   
                                ></TextFieldWithKeyboard>
                            </div>
                            <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                                <Typography variant="subtitle2" color="error" sx={{ fontWeight: "bold", visibility: this.state.wrong_password ? "visible" : "hidden", textAlign: "center" }}>Wrong Password</Typography>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-around", padding: 20, paddingTop: 0 }}>
                                <></>
                                <StyledButton disabled={this.state.lock_btn_disabled || this.state.password === ""} variant="contained" type="button" onClick={() => {
                                    client_.requestLockChange(StateLock.UNLOCKED, this.state.password);
                                    this.setState({ password: "", lock_btn_disabled: true })
                                }}>{<span style={{ display: "flex", alignContent: "center", justifyContent: "space-around" }}><img height={20} width={20} src="images/lock_unlocked_white.png" alt="" style={{paddingRight:10}}/> <Typography>Unlock</Typography></span>}</StyledButton>

                            </div>
                        </Card>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div style={{ margin: "auto", height: "100%" }}>
                    <div style={{ padding: 10, display: "flex", flexDirection: "column", justifyContent: "center", height: "100%", marginLeft: "auto", marginRight: "auto" }}>
                        <Card elevation={1} style={{ width: "400px", margin: "auto" }}>
                            <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", padding: 20, paddingBottom: 10 }}>
                                <Typography variant="h6">Advanced settings are available</Typography>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-around", padding: 20, paddingTop: 10, paddingBottom:10 }}>
                                <></>
                                <StyledButton disabled={this.state.lock_active} variant="contained" type="button" onClick={() => {
                                    // client_.requestLockChange(StateLock.LOCKED, this.state.password);
                                    this.setState({ password: "", lock_active: true, tabIndex: 99 })
                                }}>{<span style={{ display: "flex", alignContent: "center", justifyContent: "space-around" }}><img height={20} width={20} src="images/lock_locked_white.png" alt="" style={{paddingRight:10}} /> <Typography>Lock</Typography></span>}</StyledButton>

                            </div>
                        </Card>
                    </div>
                </div>
            )
        }

    }
    renderFrameOrVNC() {
        let vnc_index = 0
        let pages = window.config_quadient?.settings_pages!.map((page) => {

            if (page.vnc === false) {

                return <iframe key={page.id} style={{ display: this.state.tabIndex === page.id ? "block" : "none" }} width={"100%"} height={"100%"} src={page.page_url} />
            }
            else {
                return <div style={{ display: this.state.tabIndex === page.id ? "block" : "none", margin: "auto", height: "100%", width: "99%" }} >
                    <ActorVNC_Screen key={vnc_index + "settings"} actor_name={page.id + "_" + page.title + "_" + uuidv4()} vm_type={page.type} vm_id={page.id.toString()} />
                </div>
            }
        })
        pages?.push(<div key={99} style={{ display: this.state.tabIndex === 99 ? "block" : "none", height: "100%" }}>{this.createLockScreen()}</div>)
        return pages
    }
    render() {
        return (

            <div style={{ display: 'flex', flexDirection: 'row', width: "100%", height: "100%" }}>
                {this.createModal()}
                <StyledSideMenu>
                    <ToggleButtonGroup onChange={(event, value) => { 
                        // console.log(value)
                     }} orientation="vertical"
                        exclusive style={{ width: "100%" }}>
                        {this.createSideTabs()}
                    </ToggleButtonGroup>
                    <div style={{ display: "flex", flexDirection: "column-reverse" }}>
                        <ToggleButton sx={{ borderRadius: 0, height: 93, width: 93, display: "flex", flexDirection: "column" }} value="settings"
                            style={{ padding: 0, borderRadius: 0 }}
                            onClick={() => {
                                this.setState({ tabIndex: 99 })


                                /* if (this.state.lock_active) {
                                    this.setState({ lock_modal_open: true })
                                }
                                else {
                                    client_.requestLockChange(StateLock.LOCKED)
                                    this.process(new MsgSetStateLock({ state_lock: StateLock.LOCKED }), "actor_settings_page")
                                } */
                            }}>
                            <img style={{ width: 25, height: 25 }} src={this.state.lock_active ? "images/lock_locked.png" : "images/lock_unlocked.png"} />
                            <Typography fontWeight={this.state.lock_active ? "bold" : ""} paddingTop={1} variant="subtitle2" fontSize={12}>{this.state.lock_active ? "Locked" : "unlocked"}</Typography>
                        </ToggleButton>
                    </div>
                </StyledSideMenu>
                <div style={{ flex: 15, height: "100%", width: "100%", "backgroundColor": "white" }}>

                    {this.renderFrameOrVNC()}

                </div>
            </div >)
    }
}