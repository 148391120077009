
import React from 'react';
import Keyboard from 'react-simple-keyboard';
import "react-simple-keyboard/build/css/index.css";
import { v4 as uuidv4 } from 'uuid';
import RTT from "react-tooltip";
import { findDOMNode } from 'react-dom'
import { TextField, TextFieldProps } from '@mui/material';

interface IStateTextFieldWithKeyboard {
    show_keyboard: boolean
    value: string
    layout_name: string
}

interface IPropsTextFieldWithKeyboard {
    keyboard_type: 'numeric' | 'normal'
    text_align?: 'right' | 'center' | 'left' | undefined
    onSubmit?: () => {} | undefined
}

export class TextFieldWithKeyboard extends React.Component<TextFieldProps & IPropsTextFieldWithKeyboard, IStateTextFieldWithKeyboard>
{
    readonly state: IStateTextFieldWithKeyboard =
        {
            show_keyboard: false,
            value: "",
            layout_name: "default"
        }

    private keyboard_ref_: React.RefObject<HTMLElement> = React.createRef();
    private keyboard_bounding_ref_: React.RefObject<HTMLDivElement> = React.createRef();
    private input_ref_: React.RefObject<HTMLInputElement> = React.createRef();

    private keyboard_id_ = "keyboard_" + uuidv4();

    constructor(props: TextFieldProps & IPropsTextFieldWithKeyboard) {
        super(props);

        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.showKeyboard = this.showKeyboard.bind(this);
        this.hideKeyboard = this.hideKeyboard.bind(this);

        document.addEventListener("keyboard_opened", (e) => {
            if ((e as any).detail !== this.keyboard_id_) {
                if (this.state.show_keyboard) this.hideKeyboard();
            } else {
                if (!this.state.show_keyboard) this.showKeyboard();
            }

        });
        // Hide keyboard when clicked out of keyboard region
        document.addEventListener("keyboard_closed", (e) => {
            if ((e as any).detail === this.keyboard_id_) {
                if (this.state.show_keyboard) this.hideKeyboard();
            }
        });
    }

    showKeyboard() {
        this.setState({ show_keyboard: true });
        RTT.show(findDOMNode(this.input_ref_?.current) as Element);
    }

    hideKeyboard() {
        this.setState({ show_keyboard: false });
        RTT.hide(findDOMNode(this.input_ref_?.current) as Element);
        if (this.props.onSubmit) {
            this.props.onSubmit();
        }
    }

    componentDidMount() {
        document.addEventListener('mouseup', this.handleClickOutside);
        this.setState({ value: this.props.value as string });
    }

    componentDidUpdate(prevprops: TextFieldProps) {
        console.log("------------------", prevprops.value, this.props.value)
        if (prevprops.value !== this.props.value && this.props.value!==undefined) {
            this.setState({ value: this.props.value as string });
            (this.keyboard_ref_ as any).setInput(this.props.value);
        }
    }

    UNSAFE_componentWillUnmount() {
        document.removeEventListener('mouseup', this.handleClickOutside);
    }

    handleClickOutside(event: any) {
        if (this.keyboard_bounding_ref_ && !this.keyboard_bounding_ref_?.current?.contains(event.target)) {
            document.dispatchEvent(new CustomEvent("keyboard_closed", { detail: this.keyboard_id_ }));
        }
    }

    render() {
        const { value, onChange, ...clonedProps } = this.props;

        var keyboard_layout_default: string[] | undefined = undefined;
        var keyboard_layout_shift: string[] | undefined = undefined;

        switch (this.props.keyboard_type) {
            case 'normal':
                keyboard_layout_default = [
                    "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                    "q w e r t y u i o p [ ] \\",
                    "{lock} a s d f g h j k l ; ' {enter}",
                    "{shift} z x c v b n m , . /",
                    "{space}"
                ];
                keyboard_layout_shift = [
                    "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
                    "Q W E R T Y U I O P { } |",
                    '{lock} A S D F G H J K L : " {enter}',
                    "{shift} Z X C V B N M < > ?",
                    "{space}"
                ];
                break;
            case 'numeric':
                keyboard_layout_default = ["1 2 3", "4 5 6", "7 8 9", ". 0 {bksp}", "{enter}"];
                keyboard_layout_shift = ["1 2 3", "4 5 6", "7 8 9", ". 0 {bksp}", "{enter}"];
                break;
            default:
                keyboard_layout_default = [];
                keyboard_layout_shift = [];
        }

        return (
            <>
                <TextField {...clonedProps}
                    type={this.props.type ?? "text"}
                    data-tip data-for={'keyboard_tooltip' + this.keyboard_id_}
                    ref={this.input_ref_}
                    value={this.state.value}
                    inputProps={{ style: { textAlign: this.props.text_align ?? 'left' } }}
                    onChange={(e) => {
                        if (this.props.onChange !== undefined) this.props.onChange(e);
                        this.setState({ value: e.target.value });
                        (this.keyboard_ref_ as any).setInput(e.target.value);
                    }}
                    onClick={() => {
                        document.dispatchEvent(new CustomEvent("keyboard_opened", { detail: this.keyboard_id_ }));
                    }}
                    onFocus={(e) => {
                        e.target.selectionStart = e.target.value.length;
                    }}
                    onSubmit={() => {
                        if (this.props.onSubmit) {
                            this.props.onSubmit();
                        }
                    }}
                ></TextField>
                <RTT
                    id={'keyboard_tooltip' + this.keyboard_id_}
                    place='bottom'
                    effect='solid'
                    type='light'
                    clickable={true}
                    event='no-event'
                    eventOff='no-event'
                    globalEventOff='no-event'
                    backgroundColor='#ececec'
                    className='keyboard_tooltip_wrapper'
                    borderColor='#000000'
                    border={false}
                    delayHide={0}
                    delayShow={0}
                    delayUpdate={0}
                    scrollHide={false}
                    offset={{ bottom: 100 }}
                    
                >
                    <div style={{ fontSize: '14pt', display: this.state.show_keyboard ? "block" : "none", width:800 }} ref={this.keyboard_bounding_ref_}>
                        <Keyboard
                        
                            keyboardRef={(ref: React.RefObject<HTMLElement>) => { this.keyboard_ref_ = ref }}
                            baseClass={this.props.keyboard_type + "_" + this.keyboard_id_}
                            onInit={(keyboard: any) => {
                                keyboard.setInput(this.props?.value);
                                if (this.props.keyboard_type === "numeric") {
                                    keyboard.setOptions({ display: { '{bksp}': '<', '{enter}': '⏎ enter' } });
                                } else {
                                    keyboard.setOptions({ display: { '{shift}': 'shift ⇧', '{lock}': 'caps ⇧', '{enter}': '⏎ enter', '{bksp}': 'backspace', '{space}': ' ' } });
                                }

                            }}
                            onChange={(e: any) => {
                                this.setState({ value: e });
                                if (this.props.onChange !== undefined) {
                                    this.props.onChange({ target: { value: e } } as any);
                                }
                            }}
                            layoutName={this.state.layout_name}
                            onKeyPress={(pressed_button: string) => {
                                if (pressed_button === "{enter}") {
                                    this.hideKeyboard();
                                } else if (pressed_button === "{shift}" || pressed_button === "{lock}") {
                                    this.setState((prevState) => { return { layout_name: prevState.layout_name === "default" ? "shift" : "default" }; });
                                }
                            }}
                            layout={{ default: keyboard_layout_default, shift: keyboard_layout_shift }}
                            buttonTheme={[
                                {
                                    class: "hg-close",
                                    buttons: "{enter}"
                                },
                                {
                                    class: "hg-",
                                    buttons: "{enter}"
                                }
                            ]}
                        /> </div>
                </RTT>
            </>
        )
    }
}
