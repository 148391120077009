
import { VncScreen } from 'react-vnc';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import React from 'react';
import { ActorSocketIO } from '@ginf/appcore-actor';
import { ActorComponentIconButton, IActorComponent } from '@ginf/actor_react_component/dist';
import { MsgRequestProxy, MsgResponseProxy } from "../msg/MsgProxy"
import {withTranslation} from "react-i18next";
import {t} from 'i18next';

export function loadingUI(text: string) {
    return (
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: 'column', justifyContent: "center", alignItems: "center" }}>
            <CircularProgress size={80} sx={{ color: 'white', margin: 3 }} />
            <Typography style={{ color: "whitesmoke" }}>{text}</Typography>
        </div>
    )
}
type VNC_ScreenProps = {
    vm_id: string,
    actor_name: string,
    vm_type: string
};

type VNC_ScreenState = {
    url: string;
    password: string;
    should_reconnect: boolean;
    width: string;
};
class ActorSocketIO_VNC extends ActorSocketIO {
    store_: any = {
        MsgRequestProxy,
        MsgResponseProxy
    }
    constructor(name: string, url: string) {
        super(name, url)
    }
    process<T>(message: T, id: string): void {
    }
}
class ActorVNC_ScreenBody extends React.Component<VNC_ScreenProps, VNC_ScreenState> implements IActorComponent {

    public actor = new ActorSocketIO_VNC(this.props.actor_name, window.config_quadient?.actor_socketio_registry_url || "http://localhost:9091")

    constructor(props: VNC_ScreenProps) {
        super(props);
        this.state = {
            url: window.config_quadient?.proxy_prefix ?? "ws://192.168.100.69",
            password: "",
            should_reconnect: true,
            width: "100%",
        }
        this.actor.process = this.process.bind(this);
        setInterval(() => {
            // //console.log(this.props.vm_id, this.state)
            if (this.state.should_reconnect) {

                // //console.log("reconnecting from constructor", this.props.vm_id, this.props.vm_type)
                this.actor.emit(new MsgRequestProxy({ vmid: this.props.vm_id, type: this.props.vm_type }), "actor_proxy_proxmox")
                // //console.log("sent request")
            }
        }, 2500)
    }
    componentDidMount(): void {
        const canvas = document.querySelector('.react-vnc-canvas'); // Adjust the selector as needed
        if (canvas) {
            canvas.classList.add('custom-cursor');
        }
        /* this.setState({ width: "99.9%" })
        setTimeout(() => {
            this.setState({ width: "100%" })
        }, 1000); */

    }
    handleMouseOverCanvas = () => {
        // Set cursor style to 'default' when mouse is over the canvas
        document.body.style.cursor = 'default';
    };

    handleMouseOutCanvas = () => {
        // Set cursor style to 'default' when mouse is out of the canvas
        document.body.style.cursor = 'default';
    };
    process<T>(message: T, id: string): void {
        // //console.log("good processing", message)
        this.setState({ url: this.state.url + ":" + (message as any).port, password: (message as any).vnc_ticket, should_reconnect: false })
        // //console.log("state after set: ", this.state)
    }
    // componentDidMount() {
    //     //console.log("component did mount")
    //     this.actor.emit(new MsgRequestProxy({ vmid: this.props.vm_id, type: this.props.vm_type }), "actor_proxy_proxmox")
    // }
    handleCredentialsRequired = (rfb: any) => {
        // //console.log("sending credentials: ", this.state.password);
        rfb?.sendCredentials({ password: this.state.password });
    };
    disconnectHandler() {

        this.setState({
            password: "", url: window.config_quadient?.proxy_prefix ?? "ws://localhost", should_reconnect: true
        })
        // //console.log("disconnected")
        // //console.log("state after disconnect: ", this.state)

    }
    render(): React.ReactNode {
        window.document.body.addEventListener('mouseout', function (e) {
            if ((e.target as any).tagName === "CANVAS") {
                (e.target as any).focus();
            }
        });
        window.document.body.addEventListener('onclick', function (e) {
            if ((e.target as any).tagName === "CANVAS") {
                (e.target as any).focus();
                (e.target as any).classList.add('custom-cursor');
            }
        });
        window.document.body.addEventListener('onmouseenter', function (e) {
            if ((e.target as any).tagName === "CANVAS") {
                (e.target as any).focus();
                (e.target as any).classList.add('custom-cursor');
            }
        });

        return (
            <div style={{ width: "100%", height: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: "auto" }}>
                {this.state.password === "" ? loadingUI(t("establishing_connection")) :
                    <VncScreen
                        /* onConnect={(rfb) => {
                            this.setState({ width: "99.9%" })
                            setTimeout(() => {
                                this.setState({ width: "100%" })
                            }, 1000);
                        }} */
                        debug={true}
                        scaleViewport={true}
                        clipViewport={true}
                        onDisconnect={() => this.disconnectHandler()}
                        url={this.state.url}
                        loadingUI={loadingUI(t("connecting_vnc"))}
                        onCredentialsRequired={this.handleCredentialsRequired}
                        style={{ width: this.state.width, height: "100%", margin: "auto", maxWidth: "100rem"}}
                    ></VncScreen>}
            </div>
        );
    }
}

export const ActorVNC_Screen = withTranslation()(ActorVNC_ScreenBody);