
import { ActorSocketIO } from '@ginf/appcore-actor';
import { TIdActor } from '@ginf/appcore-actor';
import { actor_message_broker } from './config/actor';
import { MsgRegisterView, MsgStatusPrinter, MsgStatusInk, MsgAction, MsgActionCancelJob, MsgActionClearError, MsgActionClearTable, MsgActionPassthrough, MsgResultAction, EnumMaintenance, MsgActionMaintenance, EnumStatus, MsgActionCalibrateMediaPath } from './msg/index';
import { MsgTypeInterActor, MsgInterActor } from './config/msg/MsgInterActor';
import { MsgCostJob } from './msg/MsgCostJob';
import { MsgRequestProxy } from './msg/MsgProxy';
import { MsgRequestConfigs } from './msg/MsgOutputConfig';
import { MsgStatusMediaPath } from './msg/MsgStatusMediaPath';
import { MsgCounter } from './msg/MsgCounter';
import { MsgPrintJob } from './msg/MsgPrintJob';
import { MsgCheckPassword, MsgResultPassword, MsgSetStateLock, StateLock } from './msg/MsgLockControl';
import CryptoJS from 'crypto-js';
import { MsgLifeServiceTrayRemaining } from './msg/MsgLifeServiceTray';

export class ActorSocketIOViewMain extends ActorSocketIO {
  store_ = {
    String,
    MsgStatusPrinter,
    MsgRegisterView,
    MsgStatusInk,
    MsgCostJob,
    MsgAction,
    MsgRequestProxy,
    MsgStatusMediaPath,
    MsgCounter,
    MsgPrintJob,
    MsgCheckPassword,
    MsgResultPassword,
    MsgLifeServiceTrayRemaining
  };

  private emitToController(message_prm: any) {
    this.emit(message_prm, "quadient_controller");
  }

  private registerView(id_prm: TIdActor) {
    // //console.log("registerView emitting: ", id_prm)
    this.emitToController(new MsgRegisterView({ actor_id: id_prm }));
    setTimeout(() => {
      this.registerView(id_prm);
    }, 5000);
  }
  private backend_timeout_timer_: NodeJS.Timeout;
  // private printer_status_timer: NodeJS.Timeout;
  private status_printer_: EnumStatus | undefined = undefined;
  constructor(id_prm: TIdActor, uri_prm: string, options_prm?: any) {
    super(id_prm, uri_prm, options_prm);
    this.registerView(id_prm);
    this.backend_timeout_timer_ = setTimeout(() => {
      //send MsgInterActor through messageBroker
      // actor_message_broker.emit(...)
    }, 5000);
    /* this.printer_status_timer = setInterval(() => {
      if (this.status_printer_ !== undefined && this.status_printer_ === EnumStatus.UNKNOWN) {
        //console.log("sending restart request, as printer status is unknown for a long time")
        this.sendRestartRequest();
      }
    },5000) */
  }
  public requestLockChange(unlock: StateLock, password?: string) {
    if (unlock === StateLock.UNLOCKED && password) {
      this.emit(new MsgCheckPassword({ password:CryptoJS.SHA256(password).toString() }), "actor_password_protector");
    }
  }

  public sendCalibrateAction() {
    this.emitToController(new MsgAction({ action_calibrate: new MsgActionCalibrateMediaPath({}) }));
  }
  public sendMaintenanceAction(enum_maintenance_prm: EnumMaintenance) {
    this.emitToController(new MsgAction({ action_maintenance: new MsgActionMaintenance({ enum_maintenance: enum_maintenance_prm }) }));
  }

  public sendCancelJobAction() {
    this.emitToController(new MsgAction({ action_cancel_job: new MsgActionCancelJob({}) }));
  }

  public sendPassThroughAction(is_enabled_prm: boolean) {
    this.emitToController(new MsgAction({ action_passthrough: new MsgActionPassthrough({ is_enabled: is_enabled_prm }) }));
  }

  public sendClearTableAction() {
    this.emitToController(new MsgAction({ action_clear_table: new MsgActionClearTable() }));
  }

  public sendClearErrorAction() {
    this.emitToController(new MsgAction({ action_clear_error: new MsgActionClearError() }));
  }
  /*  public sendRestartRequest(){
     //console.log("restarting grpc")
     this.emitToController(new String("restart_grpc!"));
   } */
  public requestOutputConfigs() {
    //console.log("requesting output configs")
    this.emit(new MsgRequestConfigs(), "actor_settings_output");
  }

  process<T>(message: T, id: TIdActor): void {
    if (message instanceof MsgStatusPrinter) {
      // //console.log("------messagestatus printer: ", message)
      let msg: MsgInterActor = { type: MsgTypeInterActor.STATUS_PRINTER, msg: message }
      this.status_printer_ = message.enum_status;
      actor_message_broker.emit(msg, "ActorLogField")
      actor_message_broker.emit(msg, "ActorButtonStatusWrapper")
    }
    else if (message instanceof MsgStatusInk) {
      let msg: MsgInterActor = { type: MsgTypeInterActor.STATUS_INK, msg: message }
      actor_message_broker.emit(msg, "ActorSlidersCMYK")
    }
    else if (message instanceof MsgCostJob) {
      let msg: MsgInterActor = { type: MsgTypeInterActor.COST_JOB, msg: message }
      actor_message_broker.emit(msg, "ActorLastJobCost")
    }
    else if (message instanceof MsgResultAction) {
      //console.log("MsgResultAction: ", message)
    }
    else if (message instanceof MsgStatusMediaPath) {
      let msg: MsgInterActor = { type: MsgTypeInterActor.STATUS_MEDIA_PATH, msg: message }
      actor_message_broker.emit(msg, "ActorSpeed")
    }
    else if (message instanceof MsgCounter) {
      let msg: MsgInterActor = { type: MsgTypeInterActor.COUNTER, msg: message }
      actor_message_broker.emit(msg, "ActorButtonStatusWrapper")
    }
    else if (message instanceof MsgPrintJob) {
      let msg: MsgInterActor = { type: MsgTypeInterActor.PRINT_JOB, msg: message }
      actor_message_broker.emit(msg, "ActorSpeed")
    }
    else if(message instanceof MsgLifeServiceTrayRemaining){
      let msg: MsgInterActor = { type: MsgTypeInterActor.SERVICE_TRAY, msg: message }
      actor_message_broker.emit(msg, "ActorServiceTray")
    }
    else if(message instanceof MsgResultPassword){
      if((message as unknown as MsgResultPassword).valid){
        let lock_state = new MsgSetStateLock({ state_lock: StateLock.UNLOCKED })
        actor_message_broker.emit(lock_state, "actor_settings_page")
      }
      else{
        let lock_state = new MsgSetStateLock({ state_lock: StateLock.LOCKED, wrong_password: true })
        actor_message_broker.emit(lock_state, "actor_settings_page")
      }
    }
  }


};