export enum StatePassThroughInstant {
    OFF,
    ON
}

// it is a temporary override of the sub print mode from the UI
// the only purpose of this message is to set Pass-Through mode in case of emergency
// it will not set the sub mode directly, but will block the printing until turned off

export class MsgSetInstantPassThrough {
    pass_through_instant: StatePassThroughInstant | undefined;

    constructor(init: Partial<MsgSetInstantPassThrough>) {
        Object.assign(this, init);
    }
}

export class MsgSetInstantPassThroughResult {
    pass_through_instant: StatePassThroughInstant | undefined;

    constructor(init: Partial<MsgSetInstantPassThroughResult>) {
        Object.assign(this, init);
    }
}