export class StatusCartridge {
    public percentage: number = 0;
    public warning: boolean = false;
    public out: boolean = false;
    public capacity_pl: number = 0;
    public level_pl: number = 0;
}

export class MsgStatusInk {
    public cyan: StatusCartridge | undefined
    public magenta: StatusCartridge | undefined
    public yellow: StatusCartridge | undefined
    public black: StatusCartridge | undefined
    public constructor(init?: Partial<MsgStatusInk>) {
        Object.assign(this, init);
    }
}

