import { ToggleButtonGroup, ToggleButton, styled, SvgIcon, Typography, Button, } from "@mui/material"
import React from "react"
import { ActorVNC_Screen } from "./VNCScreen"
import { v4 as uuidv4 } from 'uuid';
import { ActorDefaultLocal } from "../config/actor";
import { stage_quadient } from "../config/stage";
import {withTranslation} from "react-i18next";
import i18next, {t} from 'i18next';
// import bcrypt from "bcryptjs-react"

const StyledSideMenu = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: "7%",
    backgroundColor: theme.palette.background.default
}))

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#f50057",
    ":hover": {
        backgroundColor: "#c51162",
    },
    ":disabled": {
        backgroundColor: "rgba(0, 0, 0, 0.12)",
    }
}))


const ID_DEFAULT_PAGE = 0

interface ISupportPageState {
    tabIndex: number;
}

class SupportPageBody extends React.Component<{}, ISupportPageState> {

    actor = new ActorDefaultLocal("actor_support_page", stage_quadient)

    iframes: Array<JSX.Element> = []
    iframe_map_settings = new Map()
    constructor(props: any) {
        super(props)
        this.state = {
            tabIndex: 0,
        }

        this.actor.process = this.process.bind(this);
    }
    process<T>(message: T, id: string): void {

    }
    componentDidMount() {
        this.setState({ tabIndex: ID_DEFAULT_PAGE })
    }
    createSideTabs() {
        // console.log(window.config_quadient?.settings_pages)
        return window.config_quadient?.support_pages?.map((page, index) => {
            let style = {}
            if (page.id === this.state.tabIndex) {
                style = { boxShadow: "inset 0px 0px 5px 1px #ababab", backgroundColor: "rgba(0, 0, 0, 0.08)", fontWeight: "bold" }

            }
            return (

                <ToggleButton sx={{ ...style }} onDoubleClick={() => { this.forceUpdate() }} key={index} onClick={
                    () => {
                        this.setState({ tabIndex: page.id })
                    }} style={{ padding: 0, borderRadius: 0, height: "6vw", width: "100%", display: "flex", flexDirection: "column" }} value={page.id}>
                    <img src={"images/Support-Manuals.png"} width={60} height={60} alt=""/>
                        
                    <Typography fontWeight={page.id === this.state.tabIndex ? "bold" : ""} paddingTop={1} variant="subtitle2" fontSize={12}>{t(page.title)}</Typography>
                </ToggleButton>
            )
        })
    }
    renderFrameOrVNC() {
        let vnc_index = 0
        let pages = window.config_quadient?.support_pages!.map((page) => {

            if (page.vnc === false) {

                return <iframe key={page.id} style={{ display: this.state.tabIndex === page.id ? "block" : "none" }} width={"100%"} height={"100%"} src={page.page_url} />
            }
            else {
                return <div style={{ display: this.state.tabIndex === page.id ? "block" : "none", margin: "auto", height: "100%", width: "99%" }} >
                    <ActorVNC_Screen key={vnc_index + "settings"} actor_name={page.id + "_" + page.title + "_" + uuidv4()} vm_type={page.type} vm_id={page.id.toString()} />
                </div>
            }
        })
        return pages
    }
    render() {
        return (

            <div style={{ display: 'flex', flexDirection: 'row', width: "100%", height: "100%" }}>

                <StyledSideMenu>
                    <ToggleButtonGroup onChange={(event, value) => { /* console.log(value) */ }} orientation="vertical"
                        exclusive style={{ width: "100%" }}>
                        {this.createSideTabs()}
                    </ToggleButtonGroup>

                </StyledSideMenu>
                <div style={{ flex: 15, height: "100%", width: "100%", "backgroundColor": "white" }}>

                    {this.renderFrameOrVNC()}
                    

                </div>
            </div >)
    }
}

export const SupportPage = withTranslation()(SupportPageBody)