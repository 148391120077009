export class MsgRequestConfigs { }
export class MsgRequestHighResCurrentConfig { }
export class MsgRequestProdResCurrentConfig { }

export class MsgResponseConfigs {
    configs?: Array<OutputConfig>

    constructor(init: Partial<MsgResponseConfigs>) {
        Object.assign(this, init);

    }
}
export class OutputConfig {
    guid?: string
    name?: string
    dpi?: string

    constructor(init: Partial<OutputConfig>) {
        Object.assign(this, init);

    }
}
export class MsgSetHighResConfig {
    guid_hi?: string
    name_hi?: string
    constructor(init: Partial<MsgSetHighResConfig>) {
        Object.assign(this, init);
    
    }
}
export class MsgSetProdResConfig {
    guid_prod?: string
    name_prod?: string
    constructor(init: Partial<MsgSetProdResConfig>) {
        Object.assign(this, init);
    
    }
}
