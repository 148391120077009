export enum StateLock {
    LOCKED,
    UNLOCKED
}

// this message is used to set the main print mode from the UI

export class MsgSetStateLock {
    state_lock: StateLock | undefined;
    wrong_password: boolean | undefined;

    constructor(init: Partial<MsgSetStateLock>) {
        Object.assign(this, init);
    }
}

export class MsgCheckPassword {
    public password: string | undefined;

    constructor(init: Partial<MsgCheckPassword>) {
        Object.assign(this, init);
    }
}
export class MsgResultPassword {
    public valid?: boolean;

    constructor(init: Partial<MsgResultPassword>) {
        Object.assign(this, init);
    }
}
export class MsgSetUserPassword {
    public password: string | undefined;

    constructor(init: Partial<MsgSetUserPassword>) {
        Object.assign(this, init);
    }
}