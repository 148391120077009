
import { ActorComponentSimpleButton, SimpleButtonProperties } from "@ginf/actor_react_component/dist"
import React from "react";
import { ActorDefaultLocal } from "../config/actor";
import { stage_quadient as stage_quadient } from "../config/stage";
import { MsgInterActor, MsgTypeInterActor } from "../config/msg/MsgInterActor";
import { EnumMaintenance, EnumStatus, MsgStatusPrinter } from "../msg";
import { client_ } from "..";
import { ComponentButtonTitle } from "./ComponentButtonTitle";
import { SxProps } from "@mui/material";
import { ActorSIOBridge } from "../ActorSocketIOBridge";
import { ModePrintMain, MsgSetPrintModeMain, MsgSetPrintModeMainResult } from "../msg/MsgSetPrintModeMain";
import { MsgSetInstantPassThrough, MsgSetInstantPassThroughResult, StatePassThroughInstant } from "../msg/MsgSetInstantPassThrough";
import { ModePrintSub, MsgPrintModeSub } from "../msg/MsgPrintModeSub";
import { MsgRequestCurrentPrintModeMain } from "../msg/MsgRequestCurrentPrintModeMain";
import { MsgCounter } from "../msg/MsgCounter";
import { t } from "i18next";
import { withTranslation } from "react-i18next";

class ActorButtonStatusWrapperBody extends React.Component<SimpleButtonProperties, { text: string, backColor?: string, jobs_receiving: boolean }>{

    actor: ActorDefaultLocal;
    constructor(props: any) {
        super(props);
        this.actor = new ActorDefaultLocal("ActorButtonStatusWrapper", stage_quadient);
        this.actor.process = this.process.bind(this);
        this.state = { text: this.props.text ?? "NO CONNECTION", jobs_receiving: false }
    }
    process<T>(message: T, id: string): void {
        try {
            let { msg, type } = message as unknown as MsgInterActor
            if (type === MsgTypeInterActor.STATUS_PRINTER) {
                let status_printer = msg as unknown as MsgStatusPrinter
                let text = ""
                let backColor = ""
                let jobs_receiving =this.state.jobs_receiving
                this.actor.emit(status_printer, "ActorButtonCalibrateWrapper")
                if (status_printer.info_status === "Lost connection") {
                    text = "NO CONNECTION"
                    backColor = "#9c9d9e"
                    jobs_receiving = false
                }
                else {
                    switch (status_printer.enum_status) {
                        case EnumStatus.UNKNOWN:
                            {
                                text = t("unknown")
                                backColor = "#FE0000"
                            }
                            break;
                        case EnumStatus.IDLE:
                            {
                                text = t("idle")
                                backColor = "#F29F03"
                            }
                            break;

                        case EnumStatus.FAULT:
                            {
                                text = t("fault")
                                backColor = "#FE0000"
                            }
                            break;

                        case EnumStatus.PRINTING:
                            text = t("printing")
                            backColor = "#73BF45"
                            break;
                        case EnumStatus.BUSY:
                            {
                                text = t("busy")
                                backColor = "#E67702"
                            }
                            break;
                        case EnumStatus.CANCELLING:
                            {
                                text = t("cancelling")
                                backColor = "#046FC2"
                            }
                            break;
                        case EnumStatus.PASSTHROUGH:
                            {
                                text = t("passthrough")
                                backColor = "#73BF45"
                            }
                            break;
                        case EnumStatus.UNCALIBRATED:
                            {
                                text = t("uncalibrated")
                                backColor = "#9e6700"
                            }
                            break;
                        case EnumStatus.MAINTENANCE:{
                            text = t("maintenance")
                            backColor = "#83118C"
                        
                        }
                    }
                }

                this.setState({ text: text, backColor: backColor !== "" ? backColor : undefined, jobs_receiving: jobs_receiving})
            }
            else if (type === MsgTypeInterActor.COUNTER) {
                let counter = msg as unknown as MsgCounter
                if (counter.counter_jobs_receiving !== undefined) {
                    if (counter.counter_jobs_receiving > 0) {
                        this.setState({ jobs_receiving: true })
                    }
                    else {
                        this.setState({ jobs_receiving: false })
                    }
                }
            }
        } catch (error) {

        }
    }
    render() {
        return <ActorButtonStatus style={{ ...this.props.style, color: "white", backgroundColor: this.state.backColor !== undefined ? this.state.backColor : "#9c9d9e" }}
            settings={{ disabled: /* (this.state.text !== "UNKNOWN" && this.state.text !== "NO CONNECTION") */true, ...this.props.settings, }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <p  style={{ flex: 5, lineHeight: 1.5 }}>{this.state.text}</p>
                    {this.state.jobs_receiving && <p className={this.state.jobs_receiving ? "loading" : ""} style={{ flex: 5, lineHeight: 1.1, fontSize: 12 }}>{t("printer_receiving_data")}</p>}

                </div>
            </div>
        </ActorButtonStatus>
    }
}
/* export class ActorPassthroughSwitchWrapper extends React.Component<SimpleButtonProperties, { toggleOn: boolean }>{

    actor: ActorDefaultLocal;
    constructor(props: any) {
        super(props);
        this.actor = new ActorDefaultLocal("ActorPassthroughSwitchWrapper", stage_quadient);
        this.actor.process = this.process.bind(this);
        this.state = { toggleOn: true }
    }
    process<T>(message: T, id: string): void {
        console.info("passthroughwrapper is processing messages!", message)
        this.setState({ toggleOn: message as unknown as boolean })
    }
    render() {
        return (<ActorButtonPassthroughMode style={{ ...this.props.style }} settings={{
            variant: "outlined", sx: {
                ":hover": {
                    backgroundColor: "rgba(100, 150, 69, 1)", color: "white", border: "1px solid black"
                }, backgroundColor: "#73BF45", ...this.props.settings?.sx
            }
        }}>
            {ComponentButtonTitle({ icon: <img alt='' src="images/passmode.svg" style={{ flex: 1 }} />, title: "PASSTHROUGH MODE " + (this.state.toggleOn ? "OFF" : "ON") })}
        </ActorButtonPassthroughMode>)
    }

} */

export class ActorButtonStatus extends ActorComponentSimpleButton {

    actor: ActorDefaultLocal;
    constructor(props: any) {
        super(props);
        this.actor = new ActorDefaultLocal("ActorButtonStatus", stage_quadient);
        this.actor.process = this.process.bind(this);
    }
    process<T>(message: T, id: string): void {
        console.info("ActorButtonStatus is processing messages!")
    }
    onClickFunc(event: React.MouseEvent) {
        //console.log("buttonstaus")
        this.actor.emit("status", "ActorButtonStatusWrapper")
    }
}

class ActorButtonCalibrateWrapperBody extends React.Component<SimpleButtonProperties, { hidden: boolean }>{
    actor: ActorDefaultLocal;
    constructor(props: any) {
        super(props);
        this.actor = new ActorDefaultLocal("ActorButtonCalibrateWrapper", stage_quadient);
        this.actor.process = this.process.bind(this);
        this.state = { hidden: true }
    }
    process<T>(message: T, id: string): void {
        if (message instanceof MsgStatusPrinter) {
            if (message.enum_status === EnumStatus.UNCALIBRATED) {
                this.setState({ hidden: false })
            }
            else {
                //console.log("HIDING CALIBRATE BUTTON")
                this.setState({ hidden: true })
            }
        }
    }
    render() {
        //console.log("rendering calibrate button", this.state)
        return <ActorButtonCalibrate style={{
            ...this.props.style,
            display: this.state.hidden ? "none" : "block"
        }} settings={{
            variant: "outlined", sx: {
                ":hover": {
                    backgroundColor: "rgba(140, 90, 0, 1)", color: "white", border: "1px solid black"
                }, backgroundColor: "#9e6700", ...this.props.settings?.sx
            }
        }}>{ComponentButtonTitle({ icon: <img alt='' src="images/calibrate.png" style={{ width: 30, height: 30 }} />, title: t("calibrate_now") })}</ActorButtonCalibrate>
    }

}

export class ActorButtonCalibrate extends ActorComponentSimpleButton {

    actor: ActorDefaultLocal;
    constructor(props: any) {
        super(props);
        this.actor = new ActorDefaultLocal("ActorButtonCalibrate", stage_quadient);
        this.actor.process = this.process.bind(this);
    }
    process<T>(message: T, id: string): void {

    }
    onClickFunc(event: React.MouseEvent) {
        client_.sendCalibrateAction()
    }
}

export class ActorButtonHeadClean extends ActorComponentSimpleButton {

    actor: ActorDefaultLocal;
    constructor(props: any) {
        super(props);
        this.actor = new ActorDefaultLocal("ActorButtonHeadClean", stage_quadient);
        this.actor.process = this.process.bind(this);
    }
    process<T>(message: T, id: string): void {
        console.info("ActorButtonHeadClean is processing messages!")
    }
    onClickFunc(event: React.MouseEvent) {
        client_.sendMaintenanceAction(EnumMaintenance.LIGHT)
    }
}

//create ActorButtonClearTransport
export class ActorButtonClearTransport extends ActorComponentSimpleButton {

    actor: ActorDefaultLocal;
    constructor(props: any) {
        super(props);
        this.actor = new ActorDefaultLocal("ActorButtonClearTransport", stage_quadient);
        this.actor.process = this.process.bind(this);
    }
    process<T>(message: T, id: string): void {
        console.info("ActorButtonClearTransport is processing messages!")
    }
    onClickFunc(event: React.MouseEvent) {
        client_.sendClearTableAction()
    }
}

//create ActorButtonClearError
export class ActorButtonClearError extends ActorComponentSimpleButton {

    actor: ActorDefaultLocal;
    constructor(props: any) {
        super(props);
        this.actor = new ActorDefaultLocal("ActorButtonClearError", stage_quadient);
        this.actor.process = this.process.bind(this);
        this.state = { toggleOn: false }
    }
    process<T>(message: T, id: string): void {
        console.info("ActorButtonClearError is processing messages!")
    }
    onClickFunc(event: React.MouseEvent) {
        //console.log("passthrough mode")
        client_.sendClearErrorAction()
    }
}

//create ActorButtonPassthroughMode
export class ActorButtonPassthroughMode extends ActorComponentSimpleButton {

    actor: ActorDefaultLocal;
    constructor(props: any) {
        super(props);
        this.actor = new ActorDefaultLocal("ActorButtonPassthroughMode", stage_quadient);
        this.actor.process = this.process.bind(this);
        this.setState({ toggleOn: false })
    }
    process<T>(message: T, id: string): void {
        console.info("ActorButtonPassthroughMode is processing messages!")
    }
    onClickFunc(event: React.MouseEvent) {

        //console.log("passthrough mode sending: ", this.state.toggleOn)
        this.actor.emit(this.state.toggleOn, "ActorPassthroughSwitchWrapper")
        client_.sendPassThroughAction(this.state.toggleOn)
        this.setState({ toggleOn: !this.state.toggleOn })

    }
}
export class ActorButtonMainMode extends ActorComponentSimpleButton {

    actor: ActorDefaultLocal;

    constructor(props: any) {
        super(props);
        this.actor = new ActorDefaultLocal("ActorButtonMainMode", stage_quadient);
        this.actor.process = this.process.bind(this);
    }
    process<T>(message: T, id: string): void {
        let msg = message as unknown as MsgInterActor
        if (msg.type === MsgTypeInterActor.MAIN_MODE) {
            //console.log("setting main mode")
        }
    }
    onClickFunc(event: React.MouseEvent) {
        //console.log("main mode does things")
    }
}
export class ActorButtonSubMode extends ActorComponentSimpleButton {

    actor: ActorDefaultLocal;

    constructor(props: any) {
        super(props);
        this.actor = new ActorDefaultLocal("ActorButtonMainMode", stage_quadient);
        this.actor.process = this.process.bind(this);
    }
    process<T>(message: T, id: string): void {
        let msg = message as unknown as MsgInterActor
        if (msg.type === MsgTypeInterActor.MAIN_MODE) {
            //console.log("setting main mode")
        }
    }
    onClickFunc(event: React.MouseEvent) {
        //console.log("sub mode does things")
    }
}
interface IActorButtonModeProps {
    main_button: {
        style?: React.CSSProperties,
        buttonSX?: SxProps
    },
    sub_button: {
        style?: React.CSSProperties,
        buttonSX?: SxProps
    }
}

interface IActorModeButtonsState {
    mode: ModePrintMain;
    mainText: { title: string, subtitle: string };
    subText: { title: string, subtitle: string };
    is_sub_disabled: boolean;
    passthrough_disabled: boolean;
}

class ActorModeButtonsBody extends React.Component<IActorButtonModeProps, IActorModeButtonsState> {
    private actor_bridge: ActorSIOBridge
    constructor(props: IActorButtonModeProps) {
        super(props);

        this.state = {
            mode: ModePrintMain.STANDALONE,
            mainText: {
                title: t("switch_to_inline_mode"),
                subtitle: t("current_standalone")
            },
            subText: {
                title: t("enable_passthrough"),
                subtitle: t("current_unset")
            },
            is_sub_disabled: false,
            passthrough_disabled: true
        };
        this.actor_bridge = new ActorSIOBridge("ActorBridgePrintMode", window.config_quadient?.actor_remoter_url ?? "http://10.249.18.180:9090")
        this.actor_bridge.process = this.process.bind(this);
        //console.log("requesting print mode config")
        this.actor_bridge.emit(new MsgRequestCurrentPrintModeMain(), "actor_remoter_qd")
    }

    process<T>(message: T, id: string): void {
        //console.log("processing: ", message)
        if (message instanceof MsgSetPrintModeMainResult) {
            if (message.mode_print_main === ModePrintMain.STANDALONE) {
                let update = { ...this.state }
                update.mainText.title = t("switch_to_inline_mode")
                update.mainText.subtitle = t("current_standalone")
                update.mode = ModePrintMain.STANDALONE
                update.is_sub_disabled = false
                if (this.state.passthrough_disabled) {
                    update.subText.title = t("enable_passthrough")
                    update.subText.subtitle = t("current_disabled")
                }
                else {
                    update.subText.title = t("disable_passthrough")
                    update.subText.subtitle = t("current_enabled")
                }
                this.setState(update)
            }
            else if (message.mode_print_main === ModePrintMain.INLINE) {
                let update = { ...this.state }
                update.mainText.title = t("switch_to_standalone_mode")
                update.mainText.subtitle = t("current_inline")
                update.mode = ModePrintMain.INLINE
                update.subText.title = t("instant_manual_passthrough")
                update.subText.subtitle = t("current_unset")
                update.passthrough_disabled = true
                update.is_sub_disabled = true
                this.setState(update)

            }
        }
        else if (message instanceof MsgRequestCurrentPrintModeMain) {
            this.actor_bridge.emit(new MsgSetPrintModeMain({ mode_print_main: this.state.mode }), "actor_remoter_qd")
        }
        else if (message instanceof MsgSetInstantPassThroughResult) {
            let update = { ...this.state }
            if (message.pass_through_instant === StatePassThroughInstant.ON) {
                update.passthrough_disabled = false
                if (this.state.mode !== ModePrintMain.INLINE) {
                    update.is_sub_disabled = false
                }
                update.subText.title = t("disable_passthrough")
            }
            else if (message.pass_through_instant === StatePassThroughInstant.OFF) {
                update.passthrough_disabled = true
                if (this.state.mode === ModePrintMain.INLINE) {
                    update.is_sub_disabled = true
                }
                update.subText.title = t("instant_manual_passthrough")
            }
            this.setState(update)
        }
        else if (message instanceof MsgSetInstantPassThrough) {
            let update = { ...this.state }
            if (message.pass_through_instant === StatePassThroughInstant.ON) {
                update.passthrough_disabled = false
                update.subText.title = t("disable_passthrough")
            }
            else if (message.pass_through_instant === StatePassThroughInstant.OFF) {
                update.passthrough_disabled = true
                update.subText.title =  t("instant_manual_passthrough")
            }
            this.setState(update)
        }
        else if (message instanceof MsgPrintModeSub) {

            let update = { ...this.state }
            if (message.mode_print_sub === undefined) {

                update.subText.subtitle = t("current_unset")
                update.is_sub_disabled = false

            }
            else if (message.mode_print_sub === ModePrintSub.PASS_THROUGH) {

                update.subText.subtitle = t("current_simple")+": " +t(ModePrintSub[message.mode_print_sub]) ?? t("unset")
                update.is_sub_disabled = true
            }
            else {

                update.subText.subtitle =  t("current_simple")+": " +t(ModePrintSub[message.mode_print_sub]) ?? t("unset")
                update.is_sub_disabled = false
            }
            //Disable the sub button if the mode is inline
            if(this.state.mode === ModePrintMain.INLINE){
                update.is_sub_disabled = true
            }
            this.setState(update)
        }
    }


    render() {
        return (<>
            {/* Render your ActorButtonMainMode component here */}
            <ActorButtonMainMode style={{ ...this.props.main_button.style }} settings={{
                variant: "outlined", sx: {
                    ":hover": {
                        backgroundColor: "rgba(94, 135, 160, 1)", color: "white", border: "1px solid black"
                    }, backgroundColor: "#6491b4", ...this.props.main_button.buttonSX,
                },
                onClick: () => {
                    this.actor_bridge.emit(
                        new MsgSetPrintModeMain({
                            mode_print_main: this.state.mode === ModePrintMain.STANDALONE ?
                                ModePrintMain.INLINE : ModePrintMain.STANDALONE
                        }), "actor_remoter_qd")

                }
            }}>{ComponentButtonTitle({
                icon: <img alt='' src="images/printer_mode_switch.svg" style={{ width: 30, height: 30 }}
                />,
                title: this.state.mainText.title, subtitle: this.state.mainText.subtitle
            })}</ActorButtonMainMode>

            {/* Render your ActorButtonSubMode component here */}
            <ActorButtonSubMode style={{ ...this.props.sub_button.style }} settings={{
                variant: "outlined", sx: {
                    ":hover": {
                        backgroundColor: "rgba(100, 150, 69, 1)", color: "white", border: "1px solid black"
                    }, backgroundColor: "#73BF45",
                    ":disabled": {
                        ":hover": {
                            backgroundColor: "rgba(180, 180, 180, 1)", color: "white", border: "1px solid black"
                        }, backgroundColor: "#b4b4b4"
                    }, ...this.props.sub_button.buttonSX,
                }, disabled: this.state.is_sub_disabled,
                onClick: () => {

                    if (this.state.mode === ModePrintMain.STANDALONE) {
                        if (this.state.passthrough_disabled) {
                            client_.sendPassThroughAction(true)
                            this.setState({
                                ...this.state, passthrough_disabled: false,
                                subText: { ...this.state.subText, title:t("disable_passthrough"), subtitle: t("current_enabled") }
                            })
                        }
                        else {
                            client_.sendPassThroughAction(false)
                            this.setState({
                                ...this.state, passthrough_disabled: true,
                                subText: { ...this.state.subText, title: t("enable_passthrough"), subtitle: t("current_disabled")}
                            })
                        }
                    }
                    else {

                        if (this.state.passthrough_disabled) {
                            this.actor_bridge.emit(new MsgSetInstantPassThrough({ pass_through_instant: StatePassThroughInstant.ON }), "actor_remoter_qd")
                        }
                        else {
                            this.actor_bridge.emit(new MsgSetInstantPassThrough({ pass_through_instant: StatePassThroughInstant.OFF }), "actor_remoter_qd")
                        }
                    }
                }
            }}

            >{ComponentButtonTitle({
                icon: <img alt='' src="images/passmode.svg" style={{ width: 30, height: 30 }}

                />, title: this.state.subText.title, subtitle: this.state.subText.subtitle
            })}</ActorButtonSubMode>
        </>
        );
    }
}

export const ActorButtonCalibrateWrapper = withTranslation()(ActorButtonCalibrateWrapperBody)
export const ActorButtonStatusWrapper = withTranslation()(ActorButtonStatusWrapperBody)
export const ActorModeButtons = withTranslation()(ActorModeButtonsBody)
