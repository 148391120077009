export enum EnumStatus {
    UNKNOWN = 0,
    IDLE = 1,
    PRINTING = 2,
    FAULT = 3,
    BUSY = 4,
    CANCELLING = 5,
    PASSTHROUGH = 6,
    UNCALIBRATED = 7,
    MAINTENANCE = 8

}
export class MsgStatusPrinter {
    public enum_status: EnumStatus = EnumStatus.UNKNOWN;
    public info_status: string = "";
    public constructor(init?: Partial<MsgStatusPrinter>) {
        Object.assign(this, init);
    }
}