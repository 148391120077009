import { useState } from "react";
import { ActorComponentSlider, SliderProperties } from "@ginf/actor_react_component/dist";
import { ActorDefaultLocal } from "../config/actor";
import { stage_quadient } from "../config/stage";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Typography, SvgIcon } from "@mui/material";
import React from "react";
import { MsgInterActor, MsgTypeInterActor } from "../config/msg/MsgInterActor";
import { MsgStatusInk } from "../msg";

interface PropsSlider {
    actor_name: string
}

export class ActorSliderGeneric extends ActorComponentSlider {
    actor: ActorDefaultLocal;
    constructor(props: PropsSlider) {
        super(props);
        this.actor = new ActorDefaultLocal(props.actor_name, stage_quadient);
        this.actor.process = this.process.bind(this);
        this.state = { value: this.props.defaultValue }
    }

    process<T>(message: T, id: string): void {
        this.setState({ value: message as unknown as number })
    }
    onChangeFunc(event: Event, value: number | number[], activeThumb: number): void {

    }
}
export enum CanState {
    OK,
    WARNING,
    OUT
}
interface SliderSprayState {
    value: number,
    color: string,
    can_state: CanState
}
const svg_out = <SvgIcon style={{ color: "red", fontSize: "1.2em" }}>
    <path fill="currentColor" d="M15.46 15.88L16.88 14.46L19 16.59L21.12 14.47L22.53 15.88L20.41 18L22.54 20.12L21.12 21.54L19 19.41L16.88 21.53L15.47 20.12L17.59 18L15.46 15.88M5 19C5 19.55 5.45 20 6 20H13.34C13.61 20.75 14 21.42 14.53 22H6C4.34 22 3 20.66 3 19C3 18.4 3.18 17.84 3.5 17.36L9 7.81V6C8.45 6 8 5.55 8 5V4C8 2.9 8.9 2 10 2H14C15.11 2 16 2.9 16 4V5C16 5.55 15.55 6 15 6V7.81L17.5 12.18C16.86 12.35 16.24 12.63 15.69 13L13 8.35V4H11V8.35L5.18 18.43C5.07 18.59 5 18.79 5 19Z" />
</SvgIcon>
const svg_warning = <SvgIcon style={{ color: "orange", fontSize: "1.2em" }}>
    <path fill="currentColor" d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z" />
</SvgIcon>
const svg_ok = <SvgIcon style={{ color: "green", fontSize: "1.2em" }}>
    <path fill="currentColor" d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2,4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z" />
</SvgIcon>


export class ActorSliderGenericSpray extends React.Component<SliderProperties, SliderSprayState>{

    actor: ActorDefaultLocal
    constructor(props: SliderProperties) {
        super(props);
        this.state = { value: props.defaultValue as number ?? 50, color: "red", can_state: CanState.OK }
        this.actor = new ActorDefaultLocal("ActorSliderGenericWrapper_Spray", stage_quadient);
        this.actor.process = this.process.bind(this);
    }
    process<T>(message: T, id: string): void {
        let { value, color, can_state } = message as unknown as SliderSprayState
        this.setState({ value: value, color: color, can_state: can_state })
    }
    render(): React.ReactNode {
        return (

            <div style={{ marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", height: 20 }}>
                <TaskAltIcon style={{ marginRight: 5, width: 20, fontWeight: "bold" }}></TaskAltIcon>
                <ActorSliderGeneric max={100} defaultValue={this.state.value} style={{ padding: 0, height: 8, marginRight: 5 }} settings={{
                    value: this.state.value,
                    disabled: true, sx: {
                        '& .MuiSlider-thumb': {
                            color: "yellow",
                            width: 0,
                            height: 0,
                        },
                        '& .MuiSlider-track': { color: this.state.color, height: 8, borderRadius: 4 },
                        '& .MuiSlider-rail': {
                            height: 9,
                            borderRadius: 4
                        },
                    }
                }
                } ></ActorSliderGeneric>
                {
                    this.state.can_state === CanState.WARNING ? svg_warning : ""
                }
                {
                    this.state.can_state === CanState.OUT ? svg_out : ""
                }
                {this.state.can_state === CanState.OK ? svg_ok : ""}
            </div>
        )
    }
}
export class ActorSliderGenericPrinter extends React.Component<SliderProperties, SliderSprayState>{

    actor: ActorDefaultLocal
    constructor(props: SliderProperties) {
        super(props);
        this.state = { value: props.defaultValue as number ?? 50, color: "green", can_state: CanState.OK }
        this.actor = new ActorDefaultLocal("ActorSliderGenericWrapper_Printer", stage_quadient);
        this.actor.process = this.process.bind(this);
    }
    process<T>(message: T, id: string): void {
        let { value, color, can_state } = message as unknown as SliderSprayState
        this.setState({ value: value, color: color, can_state: can_state })
    }
    render(): React.ReactNode {
        return (

            <div style={{ marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", height: 20 }}>
                <TaskAltIcon style={{ marginRight: 5, width: 20, fontWeight: "bold" }}></TaskAltIcon>
                <ActorSliderGeneric max={100} defaultValue={this.state.value} style={{ padding: 0, height: 8, marginRight: 5 }} settings={{
                    value: this.state.value,
                    disabled: true, sx: {
                        '& .MuiSlider-thumb': {
                            color: "yellow",
                            width: 0,
                            height: 0,
                        },
                        '& .MuiSlider-track': { color: this.state.color, height: 8, borderRadius: 4 },
                        '& .MuiSlider-rail': {
                            height: 9,
                            borderRadius: 4
                        },
                    }
                }
                } ></ActorSliderGeneric>
                {
                    this.state.can_state === CanState.WARNING ? svg_warning : ""
                }
                {
                    this.state.can_state === CanState.OUT ? svg_out : ""
                }
                {this.state.can_state === CanState.OK ? svg_ok : ""}
            </div>
        )
    }
}

interface propsCMYKSlider {
    c: number,
    m: number,
    y: number,
    k: number,
    c_warning: boolean,
    m_warning: boolean,
    y_warning: boolean,
    k_warning: boolean,
    c_out: boolean,
    m_out: boolean,
    y_out: boolean,
    k_out: boolean
}

// export class ActorSlidersCMYK extends React.Component<propsCMYKSlider, { sliders: propsCMYKSlider }> {
//     actor: ActorDefaultLocal

//     constructor(props: propsCMYKSlider) {
//         super(props);
//         this.actor = new ActorDefaultLocal("ActorSlidersCMYK", stage_quadient);
//         this.actor.process = this.process.bind(this);
//         this.state = {
//             sliders: this.props
//         }
//     }
//     process<T>(message: T, id: string): void {
//         console.info("ActorSlidersCMYK is processing messages!")
//     }
//     render(): React.ReactNode {
//         return (<>
//             {SlidersCMYK(this.state.sliders)}
//         </>)
//     }
// }



export class SlidersCMYK extends React.Component<{ sliderProps: propsCMYKSlider }, { values: propsCMYKSlider }> {

    colors: { color: string, value: number, track_style: any, warning: boolean, out: boolean }[] = []
    sliders: JSX.Element[] = [];
    constructor(props: any) {
        super(props)
        this.state = {
            values: {
                ...this.props.sliderProps
            }
        }
        this.actor.process = this.process.bind(this);
        

    }
    actor: ActorDefaultLocal = new ActorDefaultLocal("ActorSlidersCMYK", stage_quadient);

    createSliders(){
        this.colors = [
            { color: "C", value: this.state.values.c, track_style: { color: "#007fa6", height: 8, borderRadius: 4 }, warning: this.state.values.c_warning, out: this.state.values.c_out },
            { color: "M", value: this.state.values.m, track_style: { color: "#b31e7b", height: 8, borderRadius: 4 }, warning: this.state.values.m_warning, out: this.state.values.m_out },
            { color: "Y", value: this.state.values.y, track_style: { color: "#c3b900", height: 8, borderRadius: 4 }, warning: this.state.values.y_warning, out: this.state.values.y_out },
            { color: "K", value: this.state.values.k, track_style: { color: "#000000", height: 8, borderRadius: 4 }, warning: this.state.values.k_warning, out: this.state.values.k_out },
        ];
       return this.colors.map((color) => {
            return(
                <div style={{ marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", height: 20 }}>
                    <Typography style={{ marginRight: 5, width: 20, fontWeight: "bold" }} variant="subtitle2">{color.color}</Typography>
                    <ActorSliderGeneric max={100} defaultValue={color.value} style={{ padding: 0, height: 8, marginRight: 5 }} settings={{
                        value: color.value,
                        disabled: true, sx: {
                            '& .MuiSlider-thumb': {
                                color: "yellow",
                                width: 0,
                                height: 0,
                            },
                            '& .MuiSlider-track': color.track_style,
                            '& .MuiSlider-rail': {
                                height: 9,
                                borderRadius: 4
                            },
                        }
                    }
                    } ></ActorSliderGeneric>

                    {
                        color.warning ? svg_warning : ""
                    }
                    {
                        color.out ? svg_out : ""
                    }
                    {!color.out && !color.warning ? svg_ok : ""}
                </div >
            )
        })
    }



    process(message: any, id: string) {
        console.info("ActorSlidersCMYK is processing messages!")
        let update = {...this.state.values}
        // console.log("actorsliders: ", message)
        const { type, msg } = message as unknown as MsgInterActor
        if (type === MsgTypeInterActor.STATUS_INK) {
            let colors = msg as unknown as MsgStatusInk
            update.c = colors.cyan?.percentage!
            update.m = colors.magenta?.percentage!
            update.y = colors.yellow?.percentage!
            update.k = colors.black?.percentage!

            update.c_out = colors.cyan?.out!
            update.m_out = colors.magenta?.out!
            update.y_out = colors.yellow?.out!
            update.k_out = colors.black?.out!

            update.c_warning = colors.cyan?.warning!
            update.m_warning = colors.magenta?.warning!
            update.y_warning = colors.yellow?.warning!
            update.k_warning = colors.black?.warning!
            
            this.setState({ values: {...update} })
            // console.log("stateupdate: ",this.state ,update)
        }

        // //generate random number between 0-100
        // update.c = Math.floor(Math.random() * 100)
        // update.m = Math.floor(Math.random() * 100)
        // update.m_out = Math.random() > 0.5 ? true : false
        // update.m_warning = Math.random() > 0.5 ? true : false
        // update.y = Math.floor(Math.random() * 100)
        // update.y_out = Math.random() > 0.5 ? true : false
        // setValues({ ...update })
        // console.log("set valeus")

    }
    render(): React.ReactNode {
        return (
            <>
                {this.createSliders()}
            </>
        )
    }
}