export class MsgCostJob {
    public info_cost_job: string = "";
    public cost_job_total: number = 0;
    public currency: string = "";
    public num_page: number = 0;
    public constructor(init?: Partial<MsgCostJob>) {
        Object.assign(this, init);
    }
}

