import { ActorLocal } from "@ginf/appcore-actor-addon"
import { stage_quadient } from "./stage";
import { MsgInterActor } from "./msg/MsgInterActor";

class ActorMessageBroker extends ActorLocal {
  constructor() {
    super("InkstormMessageBroker", stage_quadient);
  }

  process<T>(message: T, id: string): void {
    console.error("ActorMessageBroker should not process messages!");
  }
}
export class ActorDefaultLocal extends ActorLocal {
  /* constructor(id:string, stage:RegistryActorLocal){
    super(id,stage)
  } */
  process<T>(message: T, id: string): void {
    console.error("ActorDefaultLocal should not process messages!")
  }
}
class ActorActionHandler extends ActorLocal {
  constructor() {
    super("ActorActionHandler", stage_quadient);
  }

  process<T>(message: T, id: string): void {
    // console.log("ActorActionHandler process messages!")
    try {
      let { type, msg } = message as unknown as MsgInterActor;
      // console.log(type, msg)
    } catch (error) {
      console.error("ActorActionHandler process messages error!", error)
    }
  }
}
export const actor_message_broker = new ActorMessageBroker();
export const actor_action_handler = new ActorActionHandler();