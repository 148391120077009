export enum ModePrintMain {
    INLINE,
    STANDALONE
}

// this message is used to set the main print mode from the UI

export class MsgSetPrintModeMain {
    mode_print_main: ModePrintMain | undefined;
    
    constructor(init: Partial<MsgSetPrintModeMain>) {
        Object.assign(this, init);
    }
}

export class MsgSetPrintModeMainResult {
    mode_print_main: ModePrintMain | undefined;

    constructor(init: Partial<MsgSetPrintModeMainResult>) {
        Object.assign(this, init);
    }
}