
export class MsgPrintJob {
    public resolution_horizontal: number = 0;
    public resolution_vertical: number = 0;
    public page_width: number = 0;
    public page_height: number = 0;
    public page_count: number = 0;
    public constructor(init?: Partial<MsgPrintJob>) {
        Object.assign(this, init);
    }
}