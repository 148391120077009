
import { ActorComponentTextField, TextfieldProperties } from "@ginf/actor_react_component/dist"
import React from "react";
import { TextField } from "@mui/material";
import { ActorDefaultLocal } from "../config/actor";
import { stage_quadient } from "../config/stage";
import { MsgInterActor, MsgTypeInterActor } from "../config/msg/MsgInterActor";
import { MsgStatusPrinter } from "../msg";
import { MsgCostJob } from "../msg/MsgCostJob";
import { MsgStatusMediaPath } from "../msg/MsgStatusMediaPath";
import { MsgPrintJob } from "../msg/MsgPrintJob";
import { MsgLifeServiceTrayRemaining } from "../msg/MsgLifeServiceTray";
import {withTranslation} from "react-i18next";
import i18next, {t} from 'i18next';

class ActorLogFieldBody extends ActorComponentTextField {

    actor: ActorDefaultLocal;
    constructor(props: any) {
        super(props);
        this.actor = new ActorDefaultLocal("ActorLogField", stage_quadient);
        this.actor.process = this.process.bind(this);
        this.state = { text: t("empty_na") }
    }
    process<T>(message: T, id: string): void {
        // console.info("ActorLogField is processing messages!")
        try {
            let { msg, type } = message as unknown as MsgInterActor
            if (type === MsgTypeInterActor.STATUS_PRINTER) {
                // //console.log(msg)
                let status_printer = msg as unknown as MsgStatusPrinter
                // //console.log("I wanna set to this: ", status_printer.info_status)
                let text = status_printer.info_status
                this.setState({ text: text })

            }
        } catch (error) {

        }
    }
    onChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {

    }
}
interface PropsActorTextfieldGeneric extends TextfieldProperties {
    actor_name: string,
    icon: JSX.Element
}
class ActorTextfieldGenericBody extends React.Component<PropsActorTextfieldGeneric, { text: string }>{

    actor: ActorDefaultLocal;
    constructor(props: any) {
        super(props);
        this.actor = new ActorDefaultLocal(this.props.actor_name, stage_quadient);
        this.actor.process = this.process.bind(this);
        this.state = { text: t("empty_na") }
    }
    process<T>(message: T, id: string): void {
        try {
            let { msg, type } = message as unknown as MsgInterActor
            if (type === MsgTypeInterActor.COST_JOB) {
                // //console.log(msg)
                let cost_job = msg as unknown as MsgCostJob
                let text = cost_job.info_cost_job + ": " + cost_job.currency + " " + cost_job.cost_job_total.toFixed(4)
                this.setState({ text: text })

            }
            else if (type === MsgTypeInterActor.STATUS_MEDIA_PATH) {
                let media_path = msg as unknown as MsgStatusMediaPath
                //console.log("settings media path")
                if (this.state.text === t("empty_na")) {
                    //console.log("media path was N/A")
                    let text = Math.round(media_path.media_speed / 25.4)
                    if(text === 0){
                        if(media_path.media_speed > 0.0){
                            this.setState({ text: "~"+text + " IPS" })
                        }
                        else{
                            this.setState({ text: t("cost_job_stopped") })
                        }
                    }
                    else{
                        this.setState({ text: text + " IPS" })
                    }


                }
                else {
                    let text = Math.round(media_path.media_speed / 25.4) + " IPS"
                    let old_text = this.state.text.split("/")
                    old_text[1] = text
                    text = old_text.join(" /")
                    this.setState({ text: text })
                }
            }
            else if (type === MsgTypeInterActor.PRINT_JOB) {
                //console.log("settings print job")
                let print_mode: string | undefined = undefined
                let printjob = msg as unknown as MsgPrintJob
                if (printjob.resolution_horizontal === 300 && printjob.resolution_vertical === 300) {
                    print_mode = "Production mode"
                }
                else if (printjob.resolution_horizontal === 600 && printjob.resolution_vertical === 600) {
                    print_mode = "Best mode"
                }
                else {
                    print_mode = printjob.resolution_horizontal + "x" + printjob.resolution_vertical + " DPI"
                }

                if (this.state.text === "N/A") {
                    //console.log("print job was N/A")
                    this.setState({ text: print_mode })
                }
                else {
                    let old_text = this.state.text.split("/")
                    old_text[0] = print_mode
                    let text = old_text.join(" /")
                    this.setState({ text: text })
                }
            }
        } catch (error) {

        }
    }
    onChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {

    }
    render() {
        return (<div style={{ marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly" }}>
            {this.props.icon}
            <TextField {...this.props.settings} style={this.props.style} InputProps={{ readOnly: true }} value={this.state.text}></TextField>
        </div >)
    }
}

class ActorTextFieldSpeedBody extends React.Component<PropsActorTextfieldGeneric, { mode: string, ips: string }>{

    actor: ActorDefaultLocal;
    constructor(props: any) {
        super(props);
        this.actor = new ActorDefaultLocal(this.props.actor_name, stage_quadient);
        this.actor.process = this.process.bind(this);
        this.state = { mode: "", ips: "" }
    }
    process<T>(message: T, id: string): void {
        console.info("ActorLogField is processing messages!")
        try {
            let { msg, type } = message as unknown as MsgInterActor

            if (type === MsgTypeInterActor.STATUS_MEDIA_PATH) {
                let media_path = msg as unknown as MsgStatusMediaPath
                //console.log("settings media path")
                if (media_path.media_speed === 0 || media_path.media_speed === undefined) {
                    this.setState({ ips: t("speed_stopped") })
                }
                else {
                    let text = ""
                    let ips = Math.round(media_path.media_speed / 25.4)
                    if (ips === 0){
                        if(media_path.media_speed > 0.0){
                            text = "~"+ips + " IPS"
                        }
                        else{
                            text = t("speed_stopped")
                        }
                    }
                    else{
                        text = ips + " IPS"
                    }
                    this.setState({ ips: text })
                }

            }
            else if (type === MsgTypeInterActor.PRINT_JOB) {
                let print_mode: string | undefined = undefined
                let printjob = msg as unknown as MsgPrintJob

                if (printjob.resolution_horizontal === 300 && printjob.resolution_vertical === 300) {
                    print_mode =t("printjob_mode_production_mode")
                }
                else if (printjob.resolution_horizontal === 600 && printjob.resolution_vertical === 600) {
                    print_mode = t("printjob_mode_best_mode")
                }
                else if (printjob.resolution_horizontal === 0 && printjob.resolution_vertical === 0) {
                    print_mode = ""
                }
                else {
                    print_mode = printjob.resolution_horizontal + "x" + printjob.resolution_vertical + " DPI"
                }
                this.setState({ mode: print_mode })
            }
        } catch (error) {

        }
    }
    renderText() {
        if (this.state.mode === t("speed_stopped") || this.state.ips === t("speed_stopped")) {
            return t("speed_stopped")
        }
        if (this.state.mode === "" && this.state.ips === "") {
            return t("empty_na")
        }
        else if (this.state.mode === "") {
            return this.state.ips
        }
        else if (this.state.ips === "") {
            return this.state.mode
        }
        else {
            return this.state.mode + " / " + this.state.ips
        }
    }
    onChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {

    }
    render() {
        return (<div style={{ marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly" }}>
            {this.props.icon}
            <TextField {...this.props.settings} style={this.props.style} InputProps={{ readOnly: true }} value={this.renderText()}></TextField>
        </div >)
    }
}
class ActorTextFieldServiceTrayBody extends React.Component<PropsActorTextfieldGeneric, { life_service_tray: string }>{

    actor: ActorDefaultLocal;
    constructor(props: any) {
        super(props);
        this.actor = new ActorDefaultLocal(this.props.actor_name, stage_quadient);
        this.actor.process = this.process.bind(this);
        this.state = { life_service_tray: "Unknown" }
    }
    process<T>(message: T, id: string): void {
        try {
            let { msg, type } = message as unknown as MsgInterActor
            if (type === MsgTypeInterActor.SERVICE_TRAY) {
                let life_tray = msg as unknown as MsgLifeServiceTrayRemaining
            
                if (life_tray.service_tray_life === undefined) {
                    this.setState({ life_service_tray:  t("life_service_tray_unknown")})
                }
                else {
                   
                    this.setState({ life_service_tray: life_tray.service_tray_life.toString() + "%" })
                }

            }
           
        } catch (error) {

        }
    }
    onChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {

    }
    render() {
        return (<div style={{ marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly" }}>
            {this.props.icon}
            <TextField {...this.props.settings} style={this.props.style} InputProps={{ readOnly: true }} value={t("life_service_tray_text")+": "+this.state.life_service_tray}></TextField>
        </div >)
    }
}

export const ActorLogField = withTranslation()(ActorLogFieldBody)
export const ActorTextfieldGeneric = withTranslation()(ActorTextfieldGenericBody)
export const ActorTextFieldSpeed = withTranslation()(ActorTextFieldSpeedBody)
export const ActorTextFieldServiceTray = withTranslation()(ActorTextFieldServiceTrayBody)