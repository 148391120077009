import React, { useState, useEffect } from 'react';
import './App.css';
import { Tabs, Tab, styled } from '@mui/material';
import ContactSupportIcon from '@mui/icons-material/ContactSupportOutlined';
import { useTheme } from '@mui/material/styles';
import { PanelRight } from './PanelRight';
import { ActorVNC_Screen, loadingUI } from './core/VNCScreen';
import { SettingsPage } from './core/SettingsPage';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { v4 as uuidv4 } from 'uuid';
import isReachable from 'is-reachable';
import { client_ } from '.';
import { actor_message_broker } from './config/actor';
import { SupportPage } from './core/SupportPage';

import {withTranslation} from "react-i18next";
import i18next, {t} from 'i18next';

export const _APP_VERSION = "20240416";
/*** ------------------ STYLES ------------------ ***/
const StyledTab = styled(Tab)(({ theme }) => ({
  flex: 1,
  fontWeight: "bold",
  fontSize: "1.4rem",
  textTransform: "none",
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.default,
  "&:hover": {
    backgroundColor: theme.palette.action.hover
  },
  "&.Mui-selected": {
    color: "rgba(0, 0, 0, 0.87)",
  }
}));
const StyledMain = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));
const StyledUpperSection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  height: '90%',
}));
const StyledRightPanel = styled('div')(({ theme }) => ({
  flex: 1,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.default,
}));

/*** ------------------ END OF STYLES ------------------ ***/
export interface IConfigOnePage {
  id: number;
  page_url: string;
  title: string;
  type: string;
  page_icon: string;
  vnc: boolean,
  ip?: string,
}
declare global {
  interface Window {
    config_quadient?: {
      mode?: string;
      actor_socketio_registry_url?: string;
      default_tab?: number;
      actor_remoter_url?: string;
      component_frame_url?: string,
      proxy_url?: string;
      proxy_prefix?: string;
      vms?: Array<{ vnc: boolean, id: number, type: string, name: string, ip: string, tabIndex: number }>;
      settings_pages?: Array<IConfigOnePage>;
      support_pages?: Array<IConfigOnePage>;
    }
  }
}
class App extends React.Component<{}, { tabIndex: number, vnc_passwords: Map<number, string>, url_available: boolean, version_clicked: number }> {
  private intervalId: NodeJS.Timeout | undefined = undefined;
  constructor(props: any) {
    super(props);
    this.state = {
      tabIndex: window.localStorage.getItem("last_page") ? parseInt(window.localStorage.getItem("last_page")!) : 0,
      vnc_passwords: new Map(),
      url_available: false,
      version_clicked: 0
    }
    if(window.config_quadient?.mode === "production"){
      console.log = function() {}
      console.error = function() {}
      console.debug = function() {}
    }
  }

  componentDidMount() {
    this.checkUrlAvailability();
    this.intervalId = setInterval(this.checkUrlAvailability, 5000);
    if (window.config_quadient?.default_tab) {
      this.setState({ tabIndex: window.config_quadient.default_tab });
    }

  }
  checkUrlAvailability = async () => {
    let urls = window.config_quadient?.vms?.map((vm, index) => {
      if (vm.vnc === false) {
        return vm.ip;
      }
    })
    urls?.map(async (url, index) => {
      try {
        if (url) {
          if (await isReachable(url) === true) {
            this.setState({ url_available: true });
          }
          else {
            this.setState({ url_available: false });
          }
        }


      } catch (error) {
        this.setState({ url_available: false });
      }
    })
  };

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }
  componentDidUpdate() {
    if (this.state.version_clicked >= 20) {
      alert("Version: " + _APP_VERSION)
    }
  }
  renderTabpanel() {
    return (
      <div style={{ display: "flex", flexDirection: "row", height: "100%", justifyContent: "space-between" }}>

        <div style={{ flex: 1 }} onClick={() => {
          setTimeout(() => {
            if (this.state.version_clicked != 0) {

              this.setState({ ...this.state, version_clicked: 0 });
            }
          }, 5000);

          this.setState({ ...this.state, version_clicked: this.state.version_clicked + 1 });
        }}>

        </div>
        <div style={{ flex: 10, height: "100%" }}>

          <Tabs sx={{ ".MuiTabs-flexContainer": { height: "100%" } }} style={{ height: "100%", backgroundColor: "#f5f5f5" }} TabIndicatorProps={{ style: { backgroundColor: "black" } }}
            indicatorColor="primary" onChange={(event, value) => { this.setState({ ...this.state, tabIndex: value }); }} value={this.state.tabIndex} >

            <StyledTab iconPosition='start' value={0} label={t("tab_support")} onClick={(event) => {
              window.localStorage.removeItem("last_page")
              actor_message_broker.emit("reset", "actor_settings_page")

            }} onDoubleClick={(event) => window.localStorage.removeItem("tab_last_page")} icon={<ContactSupportIcon></ContactSupportIcon>} />

            <StyledTab iconPosition='start' value={1} label={t("tab_flexmail")} onClick={(event) => {
              window.localStorage.removeItem("last_page")
              actor_message_broker.emit("reset", "actor_settings_page")
            }} onDoubleClick={(event) => window.localStorage.removeItem("last_page")} icon={<img alt='' style={{ width: 24, height: 24 }} src='images/mail.png'></img>} />

            <StyledTab iconPosition='start' value={2} label={t("tab_quickrip")} onClick={(event) => {

              actor_message_broker.emit("reset", "actor_settings_page")
            }} onDoubleClick={(event) => {
              window.localStorage.setItem("last_page", "2");
              setTimeout(() => {
                window.location.reload()
              }, 10);
            }} icon={<img alt='' style={{ width: 24, height: 24 }} src="images/ripe_logo.svg" />} />

            <StyledTab iconPosition='start' value={3} label={t("tab_control")} onClick={(event) => {

              actor_message_broker.emit("reset", "actor_settings_page")
              window.localStorage.removeItem("last_page")
            }} onDoubleClick={(event) => window.localStorage.removeItem("last_page")} icon={<img alt='' style={{ width: 28, height: 28 }} src="images/trojan_logo_2.png" />} />

            <StyledTab iconPosition='start' value={4} label={t("tab_settings")} onClick={(event) => {
              window.localStorage.removeItem("last_page")
              client_.requestOutputConfigs()
            }} onDoubleClick={(event) => window.localStorage.removeItem("last_page")} icon={<SettingsOutlinedIcon></SettingsOutlinedIcon>} />
          </Tabs>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 3, height: "100%" }}>
          <img src='images/quadient_logo.png' alt='' style={{ objectFit: "fill", height: "80%", paddingLeft: 80 }} />
        </div>
      </div>
    )
  }
  createPanels() {

    let panels = window.config_quadient?.vms?.map((vm, index) => {
      if (vm.vnc === true) {
        return <div key={vm.id + "div"} style={{ display: this.state.tabIndex === vm.tabIndex ? "block" : "none", height: "100%", width: "100%" }}>
          <ActorVNC_Screen key={vm.id} actor_name={vm.id + "_" + vm.tabIndex + "_" + uuidv4()} vm_id={vm.id.toString()} vm_type={vm.type} />
        </div>
      }
      else {
        return (
          <div key={vm.id + "div"} style={{ display: this.state.tabIndex === vm.tabIndex ? "block" : "none", height: "100%", width: "100%" }}>
            <div style={{ width: "100%", height: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: "auto" }}>
              {this.state.url_available === false ? loadingUI(t("loading_vnc")) :
                <iframe src={vm.ip} width={"100%"} height={"100%"} style={{ margin: "auto", marginRight: "3vw" }}></iframe>
              }
            </div>
          </div>
        )
      }
    })
    return (
      <div style={{ display: "flex", flex: 5, flexDirection: "column", height: "100%", backgroundColor: "#0E0639" }}>

        <div style={{ display: this.state.tabIndex === 0 ? "block" : "none",height: "100%", width: "100%" }}>
          <SupportPage></SupportPage>
        </div>
        {panels}



        <div style={{ display: this.state.tabIndex === 4 ? "block" : "none", height: "100%", width: "100%" }} >
          <SettingsPage></SettingsPage>
        </div>

      </div>
    )
  }
  render() {
    return (
      <div className="App" style={{ height: "100%", backgroundColor: "#f5f5f5" }}>
        <StyledMain>
          <StyledUpperSection style={{ height: "92%", display: "flex", flexDirection: "row" }}>
            {this.createPanels()}

            <StyledRightPanel>
              {PanelRight({})}
            </StyledRightPanel>
          </StyledUpperSection>
          <div style={{ height: "8%", backgroundColor:"#f5f5f5" }}>
            {this.renderTabpanel()}
          </div>
        </StyledMain>
      </div >
    );
  }

}

export default withTranslation()(App);





