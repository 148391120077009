export type MsgInterActor ={
    type: MsgTypeInterActor,
    msg: any
}
export enum MsgTypeInterActor {
    QUEUE_CONTROL_PAUSE = 1,
    QUEUE_CONTROL_START = 2,
    QUEUE_CONTROL_CANCEL = 3,
    STATUS_PRINTER = 4,
    STATUS_INK = 5,
    COST_JOB = 6,
    MAIN_MODE = 7,
    STATUS_MEDIA_PATH = 8,
    COUNTER = 9,
    PRINT_JOB = 10,
    RESULT_PASSWORD = 11,
    SERVICE_TRAY = 12,
}